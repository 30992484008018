/*
 * Servizio che gestisce gli url
*/
module UrlService {
    let app = angular.module("app");

    app.service("UrlService", function ($window: ng.IWindowService) {
        // Metodo che ritorna l'URL per il Course Manager
        this.getCourseManagerURL = () => {
            let protocol = "";
            let domain = "";
            let courseManagerUrl: any = {
                url: null,
                restUrl: null
            }
            // Calcolo il dominio di appartenenza per chiamare i servizi Rest
            let port = window.location.protocol === "http:" ? 8080 : 8443;
            protocol = window.location.protocol === "http:" ? "http" : "https";

            // Recupero il nome del dominio per caricare l'iFrame di cross-domain local storage
            let firstDot = 0;
            let lastDot = window.location.hostname.lastIndexOf(".");
            if (lastDot != -1) {
                firstDot = window.location.hostname.lastIndexOf(".", lastDot - 1);
            }
            if (firstDot == -1) {
                firstDot = 0;
            }
            // Per debug verifico se il primo pezzo del dominio è "localhost o atf", allora tengo questo invece del "www"
            if (window.location.hostname.indexOf("localhost") == 0) {
                domain = "localhostc.alloy.it";
            }
            else if ((window.location.hostname.indexOf("atf") == 0 && firstDot > 0 && firstDot <= 5) || (window.location.hostname.indexOf("test") == 0 && firstDot > 0)) {
                domain = "cmdemo.alloy.it";
            }
            else { // prima era www.
                domain = "www.generalicoursemanager.com"
            }

            courseManagerUrl.restUrl = protocol + "://" + domain + ":" + port + "/";

            courseManagerUrl.url = protocol + "://" + domain;
            return courseManagerUrl;
        };

        // Metodo che ritorna l'URL per scaricare un documento dal Course Manager
        this.getCMDownloadDocumentsURL = (documentId: string, identityToken: string) => {
            if (documentId && identityToken) {
                // Recupero la base dell'URL
                let courseManagerUrl = this.getCourseManagerURL();
                if (courseManagerUrl && courseManagerUrl.restUrl) {
                    return courseManagerUrl.restUrl + "rest-api/document/download?id=" + encodeURIComponent(documentId) + "&access_token=" + encodeURIComponent(identityToken);
                }
            }
        };

        // Metodo che ritorna l'URL per della GLP
        this.getGLPURL = (addDefaultDestinationPage: boolean) => {
            let protocol = "";
            let secondLevelDomain = "";
            let thirdLevelDomain = "";
            let url: string = null;
            // Calcolo il dominio di appartenenza per chiamare i servizi Rest
            let port = window.location.protocol === "http:" ? 80 : 443;
            protocol = window.location.protocol === "http:" ? "http" : "https";
            // Recupero il nome del dominio per caricare l'iFrame di cross-domain local storage
            let firstDot = 0;
            let lastDot = window.location.hostname.lastIndexOf(".");
            if (lastDot != -1) {
                firstDot = window.location.hostname.lastIndexOf(".", lastDot - 1);
            }
            if (firstDot == -1) {
                firstDot = 0;
            }
            secondLevelDomain = window.location.hostname.substring(firstDot, window.location.hostname.length);
            if (secondLevelDomain.charAt(0) == ".") {
                secondLevelDomain = secondLevelDomain.substring(1, window.location.hostname.length);
            }
            // Per debug verifico se il primo pezzo del dominio è "localhost o atf", allora tengo questo invece del "www"
            if (window.location.hostname.indexOf("localhost") == 0) {
                thirdLevelDomain = "localhost.";
            }
            else if (window.location.hostname.indexOf("atf") == 0 && firstDot > 0 && firstDot <= 5) {
                thirdLevelDomain = "atf.";
            }
            else if (window.location.hostname.indexOf("test") == 0 && firstDot > 0) {
                thirdLevelDomain = "test.";
            }
            else { // prima era www.
                thirdLevelDomain = "www."
            }

            url = protocol + "://" + thirdLevelDomain + secondLevelDomain;

            /* Il routing di Angular inserisce in coda all'url il nome della pagina di default, mettendolo dopo anche eventuali parametri in Query string. Pertanto se, ad esempio, sto facendo un semplice redirect
             * alla Library appenendoci il Token dell'utente (es http://localhost.alloy.it?token=DFGH39ODS), Angular lo trasformerà in http://localhostl.alloy.it?token=DFGH39ODS/#/app/login. Per prevenire tale
             * comportamento è necessario quindi specificare una pagina precisa prima degli eventuali parametri in query string. L'URL definitivo sarà, ad esempio, http://localhostl.alloy.it/#/app/login?token=DFGH39ODS
            */
            if (addDefaultDestinationPage) {
                url += "/#/app/login";
            }
            return url;
        };

        // Metodo che ritorna l'URL per della Library
        this.getLibraryURL = (addDefaultDestinationPage: boolean) => {
            let protocol = "";
            let secondLevelDomain = "";
            let thirdLevelDomain = "";
            let url: string = null;
            // Calcolo il dominio di appartenenza per chiamare i servizi Rest
            let port = window.location.protocol === "http:" ? 80 : 443;
            protocol = window.location.protocol === "http:" ? "http" : "https";
            // Recupero il nome del dominio per caricare l'iFrame di cross-domain local storage
            let firstDot = 0;
            let lastDot = window.location.hostname.lastIndexOf(".");
            if (lastDot != -1) {
                firstDot = window.location.hostname.lastIndexOf(".", lastDot - 1);
            }
            if (firstDot == -1) {
                firstDot = 0;
            }
            secondLevelDomain = window.location.hostname.substring(firstDot, window.location.hostname.length);
            if (secondLevelDomain.charAt(0) == ".") {
                secondLevelDomain = secondLevelDomain.substring(1, window.location.hostname.length);
            }
            // Per debug verifico se il primo pezzo del dominio è "localhost o atf", allora tengo questo invece del "www"
            if (window.location.hostname.indexOf("localhost") == 0) {
                thirdLevelDomain = "localhostl.";
            }
            else if (window.location.hostname.indexOf("atf") == 0 && firstDot > 0 && firstDot <= 5) {
                thirdLevelDomain = "atfl.";
            }
            else if (window.location.hostname.indexOf("test") == 0 && firstDot > 0) {
                thirdLevelDomain = "testlibrarydas.";
            }
            else { // prima era www.
                thirdLevelDomain = "librarydas."
            }

            url = protocol + "://" + thirdLevelDomain + secondLevelDomain;

            /* Il routing di Angular inserisce in coda all'url il nome della pagina di default, mettendolo dopo anche eventuali parametri in Query string. Pertanto se, ad esempio, sto facendo un semplice redirect
             * alla Library appenendoci il Token dell'utente (es http://localhostl.alloy.it?token=DFGH39ODS), Angular lo trasformerà in http://localhostl.alloy.it?token=DFGH39ODS/#/app/login. Per prevenire tale
             * comportamento è necessario quindi specificare una pagina precisa prima degli eventuali parametri in query string. L'URL definitivo sarà, ad esempio, http://localhostl.alloy.it/#/app/login?token=DFGH39ODS
            */
            if (addDefaultDestinationPage) {
                url += "/#/app/login";
            }
            return url;
        };

        // Metodo che ritorna l'URL per del Development Path
        this.getDevelopmentPathURL = () => {
            let protocol = "";
            let secondLevelDomain = "";
            let thirdLevelDomain = "";
            let url: string = null;
            // Calcolo il dominio di appartenenza per chiamare i servizi Rest
            let port = window.location.protocol === "http:" ? 80 : 443;
            protocol = window.location.protocol === "http:" ? "http" : "https";
            // Recupero il nome del dominio per caricare l'iFrame di cross-domain local storage
            let firstDot = 0;
            let lastDot = window.location.hostname.lastIndexOf(".");
            if (lastDot != -1) {
                firstDot = window.location.hostname.lastIndexOf(".", lastDot - 1);
            }
            if (firstDot == -1) {
                firstDot = 0;
            }
            secondLevelDomain = window.location.hostname.substring(firstDot, window.location.hostname.length);
            if (secondLevelDomain.charAt(0) == ".") {
                secondLevelDomain = secondLevelDomain.substring(1, window.location.hostname.length);
            }
            // Per debug verifico se il primo pezzo del dominio è "localhost o atf", allora tengo questo invece del "www"
            if (window.location.hostname.indexOf("localhost") == 0) {
                thirdLevelDomain = "localhostd.";
            }
            else if (window.location.hostname.indexOf("atf") == 0 && firstDot > 0 && firstDot <= 5) {
                thirdLevelDomain = "atfd.";
            }
            else if (window.location.hostname.indexOf("test") == 0 && firstDot > 0) {
                thirdLevelDomain = "testdevelopmentpath.";
            }
            else {
                thirdLevelDomain = "developmentpath.";
            }

            url = protocol + "://" + thirdLevelDomain + secondLevelDomain;
            return url;
        }
    });
}